import React from 'react';
import { useIntl } from 'react-intl';
import IconNotFound from '@features/common/components/IconNotFound';
import { useRouter } from 'next/router';
import SecondaryButton from '@features/common/components/SecondaryButton';

const NotFound = () => {
	const { formatMessage } = useIntl();
	const router = useRouter();

	return (
		<div className='flex flex-col my-40 items-center h-auto text-center'>
			<div className='w-full px-10 md:w-2/3 2xl:w-1/2'>
				<div>
					<IconNotFound width='w-[150px]' height='h-[150px]' />
				</div>
				<h1 className='font-bold text-2xl md:text-4xl 2xl:text-[42px]'>
					{formatMessage({ id: 'bcs-Common-PageNotFound' })}
				</h1>
				<div className='w-full flex justify-center'>
					<div className='w-40 mt-6'>
						<SecondaryButton
							onClick={() => router.push('/')}
							type='button'
							text={formatMessage({ id: 'bcs-Auth-GoToHomepage' })}
							tabIndex={1}
							testid='redirectToHomepage'
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NotFound;
