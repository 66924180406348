import React from 'react';
import Head from 'next/head';
import NotFound from '@features/layout/components/NotFound';
import { useIntl } from 'react-intl';

const NotFoundPage = () => {
	const { formatMessage } = useIntl();

	return (
		<>
			<Head>
				<title>{formatMessage({ id: 'bcs-Title-PageNotFound' })}</title>
				<link rel='icon' href='/favicon.ico' />
				<meta
					name='viewport'
					content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
				/>
				<meta name='theme-color' content='#ffffff' />
			</Head>
			<NotFound />
		</>
	);
};

export default NotFoundPage;
